'use strict';

var windowManager = require('./window-manager');

var getHash = function() {
  var hash = windowManager.get().location.hash;
  return hash;
};

module.exports = getHash;
