"use strict";

var Backbone = require("backbone");
var template = require("./forms-error-ribbon-template.hbs");
var _ = require("lodash");

var FormsErrorRibbon = Backbone.View.extend({

  initialize: function(){
    this.parseFieldNames();
  },

  parseFieldNames: function(){
    var errorNamesAttr = this.$el.data("forms-error-ribbon");
    if (errorNamesAttr){
      this.errorNames = errorNamesAttr.split(" ");
    }
  },

  render: function(errors){
    if (this.errorNames){
      var filteredErrorObj = _.pick(errors, this.errorNames);
      var filteredErrors = _.toArray(filteredErrorObj);
      var html = template(filteredErrors);
      this.$el.html(html);
    }
  },

  clearErrors: function(){
    this.$el.empty();
  }

});

module.exports = FormsErrorRibbon;
