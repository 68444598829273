'use strict';

var clickTracking = require('./tracking/click-tracking');
var signupFlow = require('./signup_flow');
var Session = require('./signup_flow/user/session');
var trackingFunctions = require('./tracking/tracking_functions');
var cookiesNotification = require('./ui/cookies-notification');
var mobileNav = require('./mobile-nav');

if (!Session.instance.get('loggedIn')) {
  signupFlow.initBaseView();
}

trackingFunctions.trackPage()
  .catch(console.error);
clickTracking.init()
  .catch(console.error);

cookiesNotification();

var hash = window.location.hash;

if(hash === '#forgot-email') {
  var select = document.querySelector('#id_reason');
  select.querySelector('[value="18"]').setAttribute('selected', true);
}

if (document.querySelector('.home-header')) {
  mobileNav.init();
}
