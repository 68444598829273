var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"notification__link\" href=\""
    + alias3((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"/privacy-policy",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":66}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Privacy Policy",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":1,"column":68},"end":{"line":1,"column":96}}}))
    + "</a>\n";
},"useData":true});