var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <li><div class=\"recaptcha\"></div></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n  <h3>"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Get Started",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":29}}}))
    + "</h3>\n</div>\n\n<div class=\"ribbon-container\" data-forms-error-ribbon=\"signupFailed\"></div>\n<div class=\"modal-body\">\n  <form id=\"signup-form\" class=\"forms signup signup-form\">\n    <ul>\n      <li class=\"name\" data-forms-field=\"name\">\n        <p class=\"required-legend\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Required",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":55}}}))
    + "</p>\n        <label for=\"name\" class=\"required\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Name",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":11,"column":43},"end":{"line":11,"column":59}}}))
    + "</label>\n        <input type=\"text\" name=\"name\" id=\"name\" />\n      </li>\n\n      <li class=\"email\" data-forms-field=\"email conflict\">\n        <label for=\"email\" class=\"required\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Email",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":16,"column":44},"end":{"line":16,"column":61}}}))
    + "</label>\n        <input type=\"text\" name=\"email\" id=\"email\" />\n      </li>\n\n      <li class=\"password\" data-forms-field=\"password\">\n        <label for=\"password\" class=\"required\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Password",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":21,"column":47},"end":{"line":21,"column":67}}}))
    + "</label>\n        <input type=\"password\" name=\"password\" id=\"password\" />\n      </li>\n\n      <p class=\"terms\">\n        <input id=\"terms_and_policy\" type=\"checkbox\" required>\n        <label for=\"terms_and_policy\">\n          "
    + ((stack1 = (lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"I agree to the <a class=\"termsofservice\">Terms of Service</a> and <a class=\"privacypolicy\">Privacy Policy</a>.",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":28,"column":10},"end":{"line":28,"column":134}}})) != null ? stack1 : "")
    + "\n        </label>\n      </p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recaptchaEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":34,"column":13}}})) != null ? stack1 : "")
    + "\n      <li class=\"submit\">\n        <button type=\"submit\" class=\"signup-submit disabled\">\n          "
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Start Building Your Website",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":38,"column":10},"end":{"line":38,"column":49}}}))
    + "\n        </button>\n      </li>\n    </ul>\n  </form>\n</div>\n\n<div class=\"modal-footer\">\n  <p>\n    "
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Already have an account?",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":47,"column":4},"end":{"line":47,"column":40}}}))
    + "\n    <a class=\"show-login modal-login\" href=\"#login\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Login",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":48,"column":52},"end":{"line":48,"column":69}}}))
    + "</a>\n  </p>\n</div>\n";
},"useData":true});