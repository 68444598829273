'use strict';

var segment = require('./segment');


var trackModal = function(modalName) {
  var title = 'Front Page ' + modalName + ' modal';

  return segment.page(title, modalName);
};

var segmentTrackSignUp = function(userId) {
  return segment.identify(userId)
    .then(segment.track.bind(segment, 'Signup'));
};

var trackPage = function() {
  var userId = document.body.getAttribute('data-user-id');

  if (userId) {
    return Promise.all([
      segment.identify(userId),
    ]);
  }

  return Promise.all([
    segment.page(),
  ]);
};

module.exports = {
  segmentTrackSignUp: segmentTrackSignUp,
  trackModal: trackModal,
  trackPage: trackPage,
};
