"use strict";

var Backbone = require("backbone");
var $ = require("jquery");
var localeHelper = require("../../helpers/locale-helper");
var _ = require("lodash");

var loggedIn = function() {
  var isLoggedIn = $("body").data("logged_in");

  if (isLoggedIn) {
    return isLoggedIn.toLowerCase() === "true";
  }
  return false;
};

var Session = Backbone.Model.extend({

  initialize: function(options) {
    _.extend(this, options);
    this.on("sync", this.complete);
    this.on("error", this.serverError);

    this.set("loggedIn", loggedIn());
  },

  complete: function() {
    this.set("loggedIn", true);
  },

  serverError: function(session, xhr) {
    var errors = {};

    if(xhr.status === 401) {
      errors.notAuthorized = gettext("Email/Password mismatch");
    }

    if(xhr.status >= 500 && xhr.status < 600) {
      errors.conflict = gettext("Oh no, there was an error. Please try again, or contact our friendly support.");
    }

    _.extend(errors, xhr.responseJSON);

    this.trigger("invalid", this, errors);
  },

  url: function(){
    return localeHelper("/api/users/login/");
  }
});

Session.instance = new Session();

module.exports = Session;
