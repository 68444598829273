"use strict";

var _ = require('lodash');
var $ = require('jquery');
var BaseView = require("../base-view");
var Overlay = require("./latitude-overlay");

var LatitudeOverlay = BaseView.extend({
  initialize: function(){
    this.$messageText = $(".ws-overlay p");
    this.$el = $(".ws-overlay");
    this.latitudeOverlay = Overlay;
    //Make sure we're always rendered
    this.render();
  },

  render: function(){
    var message = gettext("Loading...");
    this.latitudeOverlay.show(message);
    this.$el.addClass("ws-overlay--invisible");
  },

  events: {
    "click": "fadeOutModal"
  },

  fadeIn: function(){
    this.$el.addClass("fade-in");
    this.$el.removeClass("ws-overlay--invisible");
    return this.waitForTransition();
  },

  fadeOut: function(){
    this.$el.removeClass("fade-in");
    var hide = _.bind(this.hide, this);
    return this.waitForTransition().then(hide);
  },

  waitForTransition: function(){
    var dfd = $.Deferred();
    this.delay(function(){
      dfd.resolve();
    }, 150);
    return dfd.promise();
  },

  hide: function(){
    this.$el.addClass("ws-overlay--invisible");
  },

  showMessage: function(message) {
    this.replaceText(message);
    this.$messageText.show();
    this.$el.addClass("over-modal fade-in").removeClass("under-modal");
  },

  replaceText: function(message) {
    this.$messageText.text(message);
  }

});

Overlay.init();
LatitudeOverlay.instance = new LatitudeOverlay();

module.exports = LatitudeOverlay;
