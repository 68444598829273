'use strict';

var traqing = require('@yola/traqing.js');

var segment = require('./segment');


var getEventParams = function(element) {
  var action = element.getAttribute('data-track-link') ||
    element.getAttribute('data-track-event');
  var category = element.getAttribute('data-track-category') ||
    document.body.getAttribute('data-track-category');
  var label = element.getAttribute('data-track-label');

  return {
    action: action,
    props: {
      category: category,
      label: label,
    }
  };
};

var trackEvents = function() {
  var elements = document.querySelectorAll('[data-track-event]');

  var trackEvent = function(element) {
    var params = getEventParams(element);

    var listener = function() {
      // Segment replaces its global namespace during its initialization.
      // To ensure we're using the correct namespace object, analytics must be
      // retrieved dynamically during async operations, e.g. event listeners.
      traqing.segment.getInstance()
        .track(params.action, params.props);
    };

    element.addEventListener('click', listener);
  };

  Array.prototype.slice.call(elements).map(trackEvent);
};

var trackLinks = function() {
  var elements = document.querySelectorAll('[data-track-link]');

  var trackLink = function(element) {
    var params = getEventParams(element);

    traqing.segment.getInstance()
      .trackLink(element, params.action, params.props);
  };

  Array.prototype.slice.call(elements).map(trackLink);
};

var init = function() {
  return segment.initialize()
    .then(trackEvents)
    .then(trackLinks);
};

module.exports = {
  init: init,
};
