var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"I accept",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":22}}})) != null ? stack1 : "")
    + "\n";
},"useData":true});