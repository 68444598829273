var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header\">\n  <h3>"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Login to Continue",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":35}}}))
    + "</h3>\n</div>\n\n<div class=\"ribbon-container\" data-forms-error-ribbon=\"loginFailed\"></div>\n\n<div class=\"modal-body\">\n  <form id=\"login-form\" class=\"forms login-form\">\n\n    <ul>\n      <li class=\"email\" data-forms-field=\"email\">\n        <p class=\"required-legend\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Required",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":12,"column":35},"end":{"line":12,"column":55}}}))
    + "</p>\n        <label for=\"email\" class=\"required\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Email",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":61}}}))
    + "</label>\n        <input type=\"text\" name=\"email\" id=\"email\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":14,"column":58},"end":{"line":14,"column":67}}}) : helper)))
    + "\" tabindex=\"1\"/>\n      </li>\n\n      <li class=\"password\" data-forms-field=\"password\">\n        <a tabindex=\"5\" href=\""
    + alias3((lookupProperty(helpers,"locale")||(depth0 && lookupProperty(depth0,"locale"))||alias2).call(alias1,"/forgot-password",{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":18,"column":30},"end":{"line":18,"column":59}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Forgot?",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":18,"column":61},"end":{"line":18,"column":80}}}))
    + "</a>\n        <label for=\"password\" class=\"required\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Password",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":19,"column":47},"end":{"line":19,"column":67}}}))
    + "</label>\n        <input type=\"password\" name=\"password\" id=\"password\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"password") || (depth0 != null ? lookupProperty(depth0,"password") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"password","hash":{},"data":data,"loc":{"start":{"line":20,"column":68},"end":{"line":20,"column":80}}}) : helper)))
    + "\" tabindex=\"2\"/>\n      </li>\n\n      <li class=\"remember_me\">\n        <input type=\"checkbox\" name=\"remember_me\" id=\"remember_me\" tabindex=\"3\" value=\"1\" />\n        <label for=\"remember_me\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Remember me?",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":25,"column":33},"end":{"line":25,"column":57}}}))
    + "</label>\n      </li>\n\n      <li>\n        <button type=\"submit\" class=\"login-submit\" tabindex=\"4\">\n          "
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Login",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":30,"column":10},"end":{"line":30,"column":27}}}))
    + "\n        </button>\n      </li>\n    </ul>\n  </form>\n</div>\n\n<div class=\"modal-footer\">\n  <p class=\"no-account\">\n    "
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Don't have an account?",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":39,"column":4},"end":{"line":39,"column":38}}}))
    + " <a class=\"show-signup modal-signup\" href=\"#signup\">"
    + alias3((lookupProperty(helpers,"trans")||(depth0 && lookupProperty(depth0,"trans"))||alias2).call(alias1,"Sign Up",{"name":"trans","hash":{},"data":data,"loc":{"start":{"line":39,"column":90},"end":{"line":39,"column":109}}}))
    + "</a>\n  </p>\n</div>\n";
},"useData":true});