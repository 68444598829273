"use strict";

require("../../helpers/yohandlebars");
var _ = require("lodash");
var yousers = require("@yola/yousersjs");

var BaseForm = require("../forms/base-form");
var loginTemplate = require("./login.hbs");
var segment = require("../../tracking/segment");
const {
  trackLoginFormSuccess,
  trackLoginFormError,
} = require("../../tracking/gtm/data-layer");

var Login = BaseForm.extend({
  template: loginTemplate,

  events: {
    "submit .login-form": "submit",
    "click .show-signup": "showSignup",
  },

  initialize: function (options) {
    _.extend(this, options);
    this.login = yousers.requests.login;
  },

  render: function (preInserted) {
    if (!preInserted) {
      this.$el.html(this.template());
    }

    this.$email = this.$(".email > input");
    this.initForms(this);
    return this;
  },

  submit: function (e) {
    e.preventDefault();
    this.trigger("loginStarted");

    var authData = this.serialize();
    var loginSuccess = this.proxy(this.loginSuccess);
    var loginError = this.proxy(this.loginError);
    var validationErrors = this.validate(authData);

    if (validationErrors) {
      this.trigger("invalid", this, validationErrors);
      this.trigger("loginError");
      return;
    }

    this.login(authData).then(loginSuccess).catch(loginError);
  },

  loginSuccess: function () {
    this.trackLogin();
    var form = this.$el.find("form");
    this.trigger("loginSuccess", form);
  },

  trackLogin: function () {
    segment.track("Login");
    trackLoginFormSuccess();
  },

  show: function () {
    this.$el.show();
  },

  loginError: function () {
    this.trigger("invalid", this, {
      loginFailed: gettext("Invalid login or password"),
    });
    this.$el
      .find(".ws-form .ws-ui-input-group")
      .addClass("ws-ui-input-group--invalid");
    this.$el
      .find(".ws-form .ws-ui-input-group-field")
      .addClass("ws-ui-input-group-field--invalid");
    this.trigger("loginError");
    trackLoginFormError();
  },

  focus: function () {
    this.$email.focus();
  },
});

module.exports = Login;
