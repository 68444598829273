'use strict';

var scrollfix = require('scrollfix');


var elements = {};
var state = {
  captureTouches: false,
  isSwiping: false,
  isSwipingRight: false,
  menuAnimationFrame: null,
  previousScroll: 0,
  startingTouch: null,
  touchDiff: null,
};

var isHeaderFixed = function() {
  return document.body.classList.contains('menu-fixed');
};

var isMenuExpanded = function() {
  return document.body.classList.contains('menu-expanded');
};

var fixedHeader = function() {
  document.body.classList.add('menu-fixed');
};

var relativeHeader = function() {
  document.body.classList.remove('menu-fixed');
};

var showHeader = function() {
  document.body.classList.remove('menu-hidden');
  elements.header.setAttribute('aria-hidden', 'false');
};

var hideHeader = function() {
  document.body.classList.add('menu-hidden');
  elements.header.setAttribute('aria-hidden', 'true');
};

var expandMenu = function() {
  if (isMenuExpanded()) {
    return;
  }

  state.captureTouches = true;
  elements.overlay.classList.remove('hide');
  document.body.classList.add('menu-overlay');
  document.body.classList.add('menu-expanded');
  elements.expandButton.setAttribute('aria-expanded', 'true');
  elements.collapseButton.setAttribute('aria-expanded', 'true');
  elements.nav.setAttribute('aria-hidden', 'false');
};

var collapseMenu = function(event) {
  if (!isMenuExpanded()) {
    return;
  }

  var collapse = function() {
    elements.overlay.classList.add('hide');
    document.body.classList.remove('menu-overlay');
    elements.expandButton.setAttribute('aria-expanded', 'false');
    elements.collapseButton.setAttribute('aria-expanded', 'false');
    elements.nav.setAttribute('aria-hidden', 'true');
    elements.nav.removeEventListener('transitionend', collapse);
    elements.nav.scrollTop = 0;
  };

  state.captureTouches = false;
  elements.nav.addEventListener('transitionend', collapse);
  document.body.classList.remove('menu-expanded');
};

var updateHeaderPosition = function() {
  var currentScroll = window.scrollY || window.pageYOffset;
  var isBelowHeader = currentScroll > elements.header.offsetHeight;
  var isScrollingDown = currentScroll > state.previousScroll;
  var delta = Math.abs(state.previousScroll - currentScroll);
  var minDelta = 20;

  if (delta >= minDelta) {
    if (isScrollingDown && isHeaderFixed()) {
      hideHeader();
    } else {
      showHeader();
    }

    if (isBelowHeader) {
      fixedHeader();
    } else {
      relativeHeader();
    }
  }

  state.previousScroll = currentScroll;
};

var updateMenuPosition = function() {
  if (state.isSwipingRight) {
    elements.nav.style.transform = 'translateX(' + state.touchDiff + 'px)';
    elements.nav.style.overflowY = 'hidden';
  }
};

var startMenuUpdates = function() {
  var update = function() {
    state.menuAnimationFrame = window.requestAnimationFrame(update);

    updateMenuPosition();
  };

  update();
};

var stopMenuUpdates = function() {
  window.cancelAnimationFrame(state.menuAnimationFrame);
};

var onTouchStart = function(event) {
  if (!state.captureTouches) {
    return;
  }

  elements.nav.style.transition = 'none';
  state.startingTouch = event.touches[0];
};

var onTouchMove = function(event) {
  if (!state.captureTouches) {
    return;
  }

  var currentTouch = event.touches[0];

  state.touchDiff = currentTouch.clientX - state.startingTouch.clientX;
  state.isSwipingRight = state.touchDiff > 0;

  var delta = Math.abs(state.touchDiff);
  var minDelta = 50;
  var isHorizontalSwipe = delta >= minDelta;
  var isTouchingOverlay = event.target === elements.overlay;

  if (isTouchingOverlay || isHorizontalSwipe) {
    // Prevent vertical page scrolling
    // and page navigation on horizontal page swipes
    event.preventDefault();
  }

  if (isHorizontalSwipe && !state.isSwiping) {
    state.isSwiping = true;
    startMenuUpdates();
  }
};

var onTouchEnd = function(event) {
  if (!state.captureTouches) {
    return;
  }

  state.isSwiping = false;
  stopMenuUpdates();
  elements.nav.removeAttribute('style');

  var currentTouch = event.changedTouches[0];
  var diff = currentTouch.clientX - state.startingTouch.clientX;
  var minDiff = 100;
  var isRightSwipe = diff >= minDiff;

  if (isRightSwipe) {
    collapseMenu();
  }
};

var cacheElements = function() {
  elements.expandButton =
    document.querySelector('.home-nav .expand button');
  elements.collapseButton =
    document.querySelector('.home-nav .collapse button');
  elements.overlay =
    document.querySelector('.home-nav .overlay');
  elements.header =
    document.querySelector('.home-header');
  elements.nav =
    document.querySelector('.home-nav nav');
};

var addListeners = function() {
  document.body.addEventListener('touchend', onTouchEnd, true);
  document.body.addEventListener('touchmove', onTouchMove, true);
  document.body.addEventListener('touchstart', onTouchStart, true);
  elements.expandButton.addEventListener('click', expandMenu);
  elements.collapseButton.addEventListener('click', collapseMenu);
  elements.overlay.addEventListener('click', collapseMenu);
  scrollfix(elements.nav);
};

var startHeaderUpdates = function() {
  var update = function() {
    window.requestAnimationFrame(update);
    updateHeaderPosition();
  };

  update();
};

var init = function() {
  return Promise.resolve()
    .then(cacheElements)
    .then(addListeners)
    .then(startHeaderUpdates);
};

module.exports = {
  init: init,
};
