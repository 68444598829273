"use strict";

require("../../helpers/yohandlebars");
var FormsErrorRibbon = require("./forms-error-ribbon");
var FormsField = require("./forms-field");
var BaseView = require("../../base-view");
var _ = require("lodash");

var BaseForm = BaseView.extend({
  serialize: function() {
    var fields = {};

    _.each(this.$("form").serializeArray(), function(item) {
      fields[item.name] = item.value;
    });

    return fields;
  },

  initForms: function(model){
    this.initErrorRibbon();
    this.initFields();
    model.on("invalid", this.proxy(this.renderErrors));
  },

  initErrorRibbon: function(){
    var ribbonEl = this.$el.find("[data-forms-error-ribbon]");
    if (ribbonEl.length){
      this.errorRibbon = new FormsErrorRibbon({el: ribbonEl});
    }
  },

  initFields: function(){
    this.fields = [];
    var fieldEls = this.$el.find("[data-forms-field]");
    _.each(fieldEls, _.bind(function(fieldEl){
      var field = new FormsField({el:this.$(fieldEl)});
      this.fields.push(field);
    }, this));
  },

  renderErrors: function(model, errors){
    if(this.errorRibbon){
      this.errorRibbon.render(errors);
    }
    _.invokeMap(this.fields, "render", errors.fields !== undefined ? errors.fields : errors);
    var firstError = this.$el.find(".forms-invalid input").first();
    firstError.focus();
  },

  clearErrors: function(){
    if(this.errorRibbon){
      this.errorRibbon.clearErrors();
    }
    _.invokeMap(this.fields, "clearErrors");
  },

  validate: function(data) {
    var form = this.el;
    var required = _.map(this.$el.find("label.required"), function(label){
      var id = label.getAttribute("for");
      var input = form.querySelector("#" + id);
      return input.getAttribute("name");
    });

    var errors = _.reduce(required, function(_errors, field) {
      if (!data[field]){
        _errors[field] = gettext("Field is required");
      }
      return _errors;
    }, {});

    if (!_.isEmpty(errors)){
      return errors;
    }
  },

  hide: function(){
    this.$el.hide();
    this.clearErrors();
  }

});

module.exports = BaseForm;
