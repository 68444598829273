'use strict';

var printMarkup = function() {
  var markup = document.createDocumentFragment();
  var overlayElement = document.createElement('div');
  var paragraphElement = document.createElement('p');
  var boldElement = document.createElement('b');
  var loaderContainer = document.createElement('div');

  loaderContainer.setAttribute('class', 'ws-overlay-loader');
  overlayElement.setAttribute('class', 'ws-overlay ws-overlay--hidden');
  paragraphElement.setAttribute('class', 'ws-ui-text ws-ui-text--large ws-ui-text--high-emphasis ws-ui-text--align-center');

  loaderContainer.innerHTML = `<svg
  class="ws-overlay-loader__spinner ws-overlay-loader__spinner--big"
  viewBox="0 0 50 50"
>
  <circle
    class="ws-overlay-loader__circle"
    cx="25"
    cy="25"
    r="20"
    fill="none"
    stroke-width="4"
  ></circle>
</svg>
<svg
  class="ws-overlay-loader__spinner ws-overlay-loader__spinner--small"
  viewBox="0 0 50 50"
>
  <circle
    class="ws-overlay-loader__circle"
    cx="25"
    cy="25"
    r="20"
    fill="none"
    stroke-width="5"
  ></circle>
</svg>`

  overlayElement.appendChild(loaderContainer);
  paragraphElement.appendChild(boldElement);
  loaderContainer.appendChild(paragraphElement);
  overlayElement.appendChild(paragraphElement);
  markup.appendChild(overlayElement);

  return {
    markup: markup,
    overlayElement: overlayElement,
    paragraphElement: paragraphElement
  };
};

module.exports = printMarkup;
