'use strict';

var createMarkup = require('./create-markup');
var componentClasses = require('component-classes');
var overlayElement;
var paragraphElement;

var loader = {
  init: function(container) {
    var markupContainer = createMarkup();
    var markup = markupContainer.markup;
    container = container || document.body;

    overlayElement = markupContainer.overlayElement;
    paragraphElement = markupContainer.paragraphElement

    container.appendChild(markup);
  },
  show: function(text) {
    componentClasses(overlayElement)
      .remove('ws-overlay--hidden');

    paragraphElement.innerHTML = text;
  },
  hide: function() {
    componentClasses(overlayElement)
      .add('ws-overlay--hidden');
  }
};

module.exports = loader;
