'use strict';

var $ = require('jquery');
var yousers = require('@yola/yousersjs');

require('jquery.cookie');

var cookieKey = 'csrftoken';

var setCookie = function(data) {
  var cookieOptions = {
    path: '/'
  };

  $.cookie(cookieKey, data.csrf_token, cookieOptions);
};

var getTokenFromServer = function(callback) {
  return yousers
    .csrfInit()
    .get()
    .then(callback);
};

var csrfToken = {
  clear: function() {
    return $.removeCookie(cookieKey, {path: '/'});
  },

  exists: function() {
    return !!$.cookie(cookieKey);
  },

  init: function(callback) {
    var that = this;
    return this.setCsrfToken().then(function(){
      callback(that.value());
    });
  },

  setCsrfToken: function() {
    return getTokenFromServer(setCookie);
  },

  value: function() {
    return $.cookie(cookieKey);
  }
};

module.exports = csrfToken;
