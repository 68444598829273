'use strict';

var BaseLoginSignupView = require('./base-login-signup');

var EmbeddedForms = BaseLoginSignupView.extend({

  initialize: function(){
    BaseLoginSignupView.prototype.initialize.call(this);
    this.loginView.setElement('.embedded-login-form');
    this.signupView.setElement('.embedded-signup-form');
  },

  loginError: function(){
    this.overlay.fadeOut();
  },

  signupError: function(){
    this.overlay.fadeOut();
  },

  render: function() {
    if (this.loginView.el) {
      this.loginView.render(true);
    }
    if (this.signupView.el) {
      this.signupView.render(true);
    }
  }
});

module.exports = EmbeddedForms;
