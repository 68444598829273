"use strict";

var Backbone = require("backbone");
var _ = require("lodash");

var BaseView = Backbone.View.extend({

  delay: function(func, delay){
    var extraArgs = _.drop(arguments, 2);
    var that = this;
    setTimeout(function(){
      return func.apply(that, extraArgs);
    }, delay);
  },

  proxy: function(func) {
    var that = this;
    return function() {
      return func.apply(that, arguments);
    };
  }

});

module.exports = BaseView;
