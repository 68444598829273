/* global grecaptcha */
"use strict";

require("handlebars");
var _ = require("lodash");
var yousers = require("@yola/yousersjs");

var BaseForm = require("../forms/base-form");
var config = require("../../helpers/config");
var getCurrentLocale = require("../../helpers/get-current-locale");
var localeHelper = require("../../helpers/locale-helper");
var renderRecaptcha = require("../../helpers/render-captcha");
var signupTemplate = require("./signup.hbs");
var trackingFunctions = require("../../tracking/tracking_functions");
const {
  trackSignupFormSuccess,
  trackSignupFormError,
} = require("../../tracking/gtm/data-layer");

var Signup = BaseForm.extend({
  recaptchaFailureMessage: gettext("Please verify you're not a robot"),
  template: signupTemplate,
  tracking: trackingFunctions,

  events: {
    "submit .signup-form": "submit",
    "click .show-login": "showLogin",
  },

  initialize: function (options) {
    _.extend(this, options);
    this.isSubmitting = false;
  },

  render: function (preRendered) {
    var recaptchaEnabled = config.get("registrationRecaptchaEnabled");

    if (!preRendered) {
      this.$el.html(
        this.template({
          recaptchaEnabled: recaptchaEnabled,
        })
      );
    }

    this.$name = this.$(".name > input");
    this.initForms(this);
    this.insertTermsLinks();

    if (recaptchaEnabled) {
      renderRecaptcha(".recaptcha");
    }

    var toggleSubmitButton = function (e) {
      var submitBtn = this.$(".signup-submit");

      submitBtn.toggleClass("disabled");
      submitBtn.toggleClass("ws-ui-action-button--disabled");
    };

    var termsAndPolicyCheckbox = this.$("#terms_and_policy");
    termsAndPolicyCheckbox.on("change", toggleSubmitButton.bind(this));

    return this;
  },

  submit: function (e) {
    e.preventDefault();

    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    this.trigger("signupStarted");

    var data = this.serialize();
    var resetSubmittingFlag = this.resetSubmittingFlag.bind(this);
    var signupSuccess = this.signupSuccess.bind(this);
    var signupError = this.signupError.bind(this);
    var validationErrors = this.validate(data);
    if (validationErrors) {
      this.trigger("invalid", this, validationErrors);
      this.trigger("signupError");
      resetSubmittingFlag();
      return;
    }

    return this.register(data)
      .then(signupSuccess)
      .catch(signupError)
      .finally(resetSubmittingFlag);
  },

  resetSubmittingFlag: function () {
    this.trigger();
    this.isSubmitting = false;
  },

  register: yousers.requests.register,

  trackAndLogin: function (userId, data) {
    var loginUser = function () {
      return this.login(data);
    };

    trackSignupFormSuccess();

    return Promise.all([this.tracking.segmentTrackSignUp(userId)])
      .timeout(1000)
      .catch(console.error)
      .finally(loginUser.bind(this));
  },

  login: yousers.requests.login,

  signupSuccess: function (response) {
    return this.trackAndLogin(response.id, this.serialize()).then(
      this.trigger.bind(this, "signupSuccess", this.$el.find("form"))
    );
  },

  signupError: function (error) {
    var response = error.response;

    trackSignupFormError();

    return response.json().then(this.handleErrorResponse.bind(this, response));
  },

  handleErrorResponse: function (response, data) {
    var errors = {};

    if (response.status === 409) {
      errors.signupFailed = gettext("A user with this email already exists");
    }
    if (response.status === 400) {
      _.extend(errors, data);
      var fieldsMap = {
        password: gettext("Ensure this field has at least 8 characters"),
        email: gettext("Enter a valid email address"),
      };
      var fieldErrors = _.reduce(
        _.entries(errors.fields),
        function (_errors, field) {
          if (!_errors[field[0]]) {
            _errors[field[0]] = fieldsMap[field[0]];
          }
          return _errors;
        },
        {}
      );
      errors.fields = fieldErrors;
      if (data.captcha) {
        errors.signupFailed = this.recaptchaFailureMessage;
      } else {
        errors.signupFailed = gettext("Please correct the mentioned fields");
      }
    }
    if (response.status === 429) {
      errors.signupFailed = gettext(
        "Sorry, we're getting too many signups at the moment. Please try " +
          "again later."
      );
    }
    if (response.status >= 500) {
      errors.signupFailed = gettext(
        "Please try again. There was an issue processing your sign up. Please try again or contact our friendly support team."
      );
    }
    if (typeof grecaptcha !== "undefined") {
      grecaptcha.reset();
    }
    this.trigger("invalid", this, errors);
    this.trigger("signupError");
  },

  show: function () {
    this.$el.show();
  },

  focus: function () {
    this.$name.focus();
  },

  insertTermsLinks: function () {
    var tosHref = localeHelper("/terms");
    var privacyPolicyHref = localeHelper("/privacy-policy");

    this.$el.find(".termsofservice").attr("href", tosHref);
    this.$el.find(".privacypolicy").attr("href", privacyPolicyHref);
  },

  serialize: function () {
    var data = BaseForm.prototype.serialize.call(this);

    //Convert g-recaptcha field to more succinct and userservice expected value
    if (data["g-recaptcha-response"]) {
      data.captcha = data["g-recaptcha-response"];
      delete data["g-recaptcha-response"];
    }

    data.locale = getCurrentLocale();

    return data;
  },

  validate: function (data) {
    var errors = _.extend({}, BaseForm.prototype.validate.call(this, data));
    var captchaEnabled = config.get("registrationRecaptchaEnabled");

    if (captchaEnabled && !data.captcha) {
      errors.signupFailed = this.recaptchaFailureMessage;
    }

    if (!_.isEmpty(errors)) {
      return errors;
    }
  },
});

module.exports = Signup;
