function pushToDataLayer(data) {
  // try...catch is needed to prevent unhandles errors when some browser extension blocks trackers
  // https://github.com/yola/production/issues/11319
  try {
    const { push = Function.prototype } = window.dataLayer;
    push(data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function trackSignupFormSuccess() {
  pushToDataLayer({ event: "signup_form_success" });
}

function trackSignupFormError() {
  pushToDataLayer({ event: "signup_form_error" });
}

function trackLoginFormSuccess() {
  pushToDataLayer({ event: "login_form_success" });
}

function trackLoginFormError() {
  pushToDataLayer({ event: "login_form_error" });
}

module.exports = {
  trackSignupFormSuccess,
  trackSignupFormError,
  trackLoginFormSuccess,
  trackLoginFormError,
};
