'use strict';

var Login = require('./forms/login');
var Signup = require('./forms/signup');
var LatitudeOverlay = require('../latitude-overlay/index');
var BaseView = require('../base-view');

var BaseLoginSignupView = BaseView.extend({

    initialize: function(){
      this.loginView = new Login();
      this.listenTo(this.loginView, 'loginStarted', this.loginStarted);
      this.listenTo(this.loginView, 'loginSuccess', this.loginSuccess);
      this.listenTo(this.loginView, 'loginError', this.loginError);

      this.signupView = new Signup();
      this.listenTo(this.signupView, 'signupStarted', this.signupStarted);
      this.listenTo(this.signupView, 'signupSuccess', this.signupSuccess);
      this.listenTo(this.signupView, 'signupError', this.signupError);

      this.overlay = LatitudeOverlay.instance;
      this.loginMessageTimeout;
    },

    changeLoginMessage: function(){
      var message = gettext('Refreshing your profile...');
      this.overlay.replaceText(message);
    },

    loginStarted: function(){
      var message = gettext('Logging in. Please wait...');
      this.overlay.showMessage(message);

      this.loginMessageTimeout = setTimeout(this.changeLoginMessage.bind(this), 3000);
    },

    loginSuccess: function(form){
      this.trigger('loginSuccess', form);
      clearTimeout(this.loginMessageTimeout);
    },

    loginError: function(){
      this.overlay.fadeOut();
      clearTimeout(this.loginMessageTimeout);
    },

    signupStarted: function(){
      var message = gettext('Creating account. Please wait...');
      this.overlay.showMessage(message);
    },

    signupSuccess: function(form){
      this.trigger('signupSuccess', form);
    },

    focusForms: function(event){
      // Lots of other 'hidden' events can bubble up from the forms,
      // so we need to make sure they came from this element
      if (this.$el.is(event.target)){
        this.loginView.focus();
        this.signupView.focus();
      }
    }
});

module.exports = BaseLoginSignupView;
