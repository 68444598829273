var youi = require('@yola/youi').default;
var Handlebars = require('handlebars');
var cookies = require('js-cookie');
var getUser = require('../../helpers/get-user');
var _ = require('lodash');

var contentHbs = require('./template/content.hbs');
var policyLink = require('./template/policy-link.hbs');
var buttonTextHbs = require('./template/buttonText.hbs');


var setNotificationCookie = function () {
  cookies.set('cookies-accepted', true, { expires: 365 });
};

var notifyUser = function () {
  if (!cookies.get('cookies-accepted') && !cookies.get('cookie_consent') && !document.querySelector('ws-cookie-banner')) {
    var cookiesNotification = youi.views.notification.make();
    var content = Handlebars.compile(contentHbs());

    cookiesNotification.getContext = function () {
      return {
        content: content({
          policyLink: policyLink(),
        }),
        buttonText: buttonTextHbs,
      };
    };

    // extension of baseView close method defined in youi library
    var closeModal = cookiesNotification.close;
    cookiesNotification.close = function () {
      setNotificationCookie();
      return closeModal.call(this);
    };

    cookiesNotification.render();
  }
};

module.exports = function () {
  return getUser()
    .then(setNotificationCookie)
    .catch(notifyUser);
};
