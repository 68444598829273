"use strict";

var Page = require("./page");

var initBaseView = function(){
  Page.instance = new Page({el: "body"});
  Page.instance.render();
};

module.exports = {
  initBaseView: initBaseView
};
