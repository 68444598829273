"use strict";

require("../helpers/yohandlebars");

var _ = require("lodash");
var BaseView = require("../base-view");
var Overlay = require("../latitude-overlay/index");
var config = require("../helpers/config");
var EmbeddedForms = require("./embedded-forms");
var getParam = require("../helpers/get-param");
var getHash = require("../helpers/get-hash");
var ajaxConfigPromise = require("../helpers/ajax-config");


var Page = BaseView.extend({

  initialize: function(){
    var enableSubmit = _.bind(this.enableSubmit, this);
    var embeddedFormsOptions = {
      el: "#page-content"
    };

    this.overlay = Overlay.instance;
    this.embeddedForms = new EmbeddedForms(embeddedFormsOptions);

    this.listenTo(this.embeddedForms, "loginSuccess", this.loginSuccess);
    this.listenTo(this.embeddedForms, "signupSuccess", this.signupSuccess);

    ajaxConfigPromise.done(enableSubmit);
  },

  render: function() {
    this.overlay.render();
    this.$el.append(this.overlay.$el);

    this.embeddedForms.render();
  },

  enableSubmit: function() {
    var button = this.$el.find(".auth-submit[disabled]:not(.signup-submit.ws-ui-action-button)");

    button.removeAttr("disabled");
    button.removeClass('ws-ui-action-button--disabled');
  },

  loginSuccess: function(form){
    var msg = gettext("Login successful. Preparing your MyYola dashboard...");
    this.overlay.showMessage(msg);
    this.delay(this.postLoginRedirect, 400, form);
  },

  postLoginRedirect: function(form){
    var myyolaUrl = config.get("myyolaUrl");
    var redirectUrl = this.getRedirectUrl(myyolaUrl, form);
    window.location.assign(redirectUrl);
  },

  signupSuccess: function(form){
    var message = gettext("Sign up successful. Preparing Template Gallery for you...");
    this.overlay.showMessage(message);
    this.delay(this.postSignupRedirect, 400, form);
  },

  postSignupRedirect: function(form){
    var templateGalleryUrl = config.get("templateGalleryUrl");
    var redirectUrl = this.getRedirectUrl(templateGalleryUrl, form);
    window.location.assign(redirectUrl);
  },

  getRedirectUrl: function(defaultUrl, form) {
    var urlParam = getParam("next");
    var formRedirect;

    if(urlParam) {
      var hash = getHash();
      urlParam = urlParam + hash;
    }

    if(form && form.data) {
      formRedirect = form.data("redirect");
    }

    return decodeURIComponent(urlParam || formRedirect || defaultUrl);
  }


});

module.exports = Page;
