"use strict";

var Backbone = require("backbone");
var template = require("./forms-field-error-template.hbs");
var _ = require("lodash");


var FormsField = Backbone.View.extend({
  initialize: function(){
    /* global $ */
    this.parseFieldNames();
    this.errorText = $("<p>", {
      "class": "ws-ui-field-status ws-ui-field-status--error"
      }
    )
  },

  parseFieldNames: function(){
    var fieldNamesAttr = this.$el.data("forms-field");
    if (fieldNamesAttr){
      this.fieldNames = fieldNamesAttr.split(" ");
    }
  },

  render: function(allErrors){
    this.clearErrors();
    var filteredErrors = this.filterErrors(allErrors);
    if (filteredErrors.length > 0){
      this.showErrors(filteredErrors);
    }
  },

  clearErrors: function(){
    this.errorText.empty();
    this.errorText.detach();
    this.$el.removeClass("forms-invalid");
    this.$el.find('.ws-ui-input-group').removeClass('ws-ui-input-group--invalid')
    this.$el.find('.ws-ui-input-group-field').removeClass('ws-ui-input-group-field--invalid')
  },

  filterErrors: function(allErrors){
    var fieldErrorObj = _.pick(allErrors, this.fieldNames);
    var fieldErrors = _.toArray(fieldErrorObj);
    return fieldErrors;
  },

  showErrors: function(errors){
    var errorContent = template(errors);

    this.$el.addClass("forms-invalid");
    this.$el.find('.ws-ui-input-group').addClass('ws-ui-input-group--invalid')
    this.$el.find('.ws-ui-input-group-field').addClass('ws-ui-input-group-field--invalid')

    this.errorText.append(errorContent)
    this.$el.append(this.errorText)
  },
});

module.exports = FormsField;
