/* global csrfToken */
"use strict";

var csrfToken = require("../csrf-token");
var Backbone = require("backbone");

var setToken = function(token) {
  Backbone.$.ajaxPrefilter(function(options, originalOptions, jqXHR) {
    jqXHR.setRequestHeader("X-CSRFToken", token);
  });
};

module.exports = csrfToken.init(setToken);
