'use strict';

var traqing = require('@yola/traqing.js');
var config = require('../helpers/config');
var updateUserProperty = require('../helpers/update-user-property');

var init;

var initialize = function() {
  if (init) {
    return init;
  }

  if (!traqing.segment.getInstance()) {
    var writeKey = document.body.getAttribute('data-segment-io');
    var publicApiUrl = config.get('publicApi');

    traqing.segment.init(writeKey, publicApiUrl);
  }

  init = Promise.resolve(traqing.segment);

  return init;
};

var isAnalyticsEnabled = function () {
  if (navigator.doNotTrack === null) return true;

  return !Number(navigator.doNotTrack);
};

var identify = function(userId) {
  var identifyUser = function() {
    var frontEndTrackingEnabled = isAnalyticsEnabled();

    return traqing.segment.identify(
      userId,
      { frontEndTrackingEnabled: frontEndTrackingEnabled },
      function() {
        updateUserProperty('frontend_tracking_enabled', frontEndTrackingEnabled);
      }
    );
  };

  return initialize()
    .then(identifyUser);
};

var page = function(title, path) {
  var trackPage = function() {
    return traqing.segment.page(title, path);
  };

  return initialize()
    .then(trackPage);
};

var track = function(event) {
  var trackEvent = function() {
    return traqing.segment.track(event);
  };

  return initialize()
    .then(trackEvent);
};

module.exports = {
  initialize: initialize,
  identify: identify,
  page: page,
  track: track,
};
